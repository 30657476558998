/** @jsx jsx */
import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { Container, Divider, jsx } from 'theme-ui';
import { GatsbyHelmet } from '../utils/helmet';
import { DefaultLayout } from '../components/DefaultLayout';
import RenderWorkItem from '../components/RenderWorkItem';
import { useGtag } from '../utils/utils';

interface ProjectItemObj {
  node: {
    html: HTMLElement
    excerpt: string
    frontmatter: {
      title: string
      category: string
      image?: unknown
      slug: string
      published: boolean
      projectTitle: string
      projectClient: string
      projectAgency: string
      portfolioCategories: any
    }
  }
}

interface PageTemplateProps {
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
        author: {
          name: string
          url: string
        }
      }
    }
    allMarkdownRemark: {
      edges: ProjectItemObj[]
    }
  }
}

const ProjectsPage: React.FC<PageTemplateProps> = ({ data }) => {
  return (
    <DefaultLayout>
      <GatsbyHelmet
        title={`Projects - ` + data.site.siteMetadata.title}
        description={data.site.siteMetadata.description}/>
      <h6 sx={{variant: 'link.breadcrumbHeading'}}>
        projects.
        <Divider sx={{variant: 'link.breadcrumbDivider'}}/>
      </h6>
      <Container sx={{variant: 'workProjectContainer'}}>
        <h1 sx={{ fontWeight: '400', variant: 'heading' }}>
          Some contracting projects,<br/> side projects & hacks.
        </h1>
        <h3 sx={{ fontWeight: '400' }}>
          See something you like? Click for more details.
        </h3>
        <h4 sx={{ fontWeight: '400' }}>
          Interested in working together? <Link
          sx={{ variant: 'link.heading', display: 'inline' }}
          onClick={() => useGtag('event','click', 'menu',{ name: 'say-hello-projects' })}
          to={'/contact'}>Say hello.</Link>
        </h4>
        <div>
          {data.allMarkdownRemark.edges.map((item: ProjectItemObj) => (
            <RenderWorkItem data={item} key={item.node.frontmatter.title}/>
          ))}
        </div>
      </Container>
    </DefaultLayout>);
};

export default ProjectsPage;

export const query = graphql`
  query ProjectsPageTemplateQuery {
    site {
      siteMetadata {
        title
        description
        author {
          name
          url
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___order], order: ASC }, filter: { frontmatter: { category: { eq: "projects" } } }) {
      edges {
        node {
          html
          excerpt
          frontmatter {
            title
            category
            slug
            image {
              childImageSharp {
                sizes(maxWidth: 1080, quality: 100) {
                  ...GatsbyImageSharpSizes_tracedSVG
                }
              }
            }
            projectTitle
            projectClient
            projectAgency
            portfolioCategories
            published
          }
        }
      }
    }
  }
`;
