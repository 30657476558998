/** @jsx jsx */
import * as React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { MaxLengthString, useGtag } from '../utils/utils';
import { jsx, SxStyleProp } from 'theme-ui';

interface WorkItemObj {
  node: {
    html: HTMLElement
    excerpt: string
    frontmatter: {
      title: string
      category: string
      image?: unknown
      slug: string
      published: boolean
      projectTitle: string
      projectClient: string
      projectAgency: string
      portfolioCategories: any
    }
  }
}

interface WorkItemData {
  data: WorkItemObj
}

const RenderWorkItem: React.FC<WorkItemData> = ({ data }) => {
  const item = data.node.frontmatter;
  if (!item.published) {
    return null;
  }
  return (
    <Link sx={WorkItemWrapper}
          onClick={() => {
            useGtag(
              'event',
              'click',
              'menu',
              { name: `view-portfolio-item-${item.category}-${item.slug}`, },
            );
          }}
          to={`/${item.category}/${item.slug}`}>
      <div sx={ImageWrapper}>
        <Img
          // @ts-ignore
          sizes={item.image.childImageSharp.sizes}/>
        <div
          sx={ProjectDetails}
          className="work-item-details">
          {item.projectTitle &&
          <div sx={{fontWeight:'bold', fontSize: ['3', '4', '5'], marginBottom:'2rem', lineHeight: 'none'}}>
            {MaxLengthString(item.projectTitle, 40)}
          </div>}
          {item.projectClient &&
          <div sx={{ fontWeight:'semibold', fontSize: ['2', '3', '4'], lineHeight: ['none', 'tight', 'snug'], marginBottom:'1rem'}}>
            {MaxLengthString(item.projectClient, 40)}
          </div>}
          {item.projectAgency &&
          <div sx={{ fontWeight:'semibold', fontSize: ['2', '3', '4'], lineHeight: ['none', 'tight', 'snug'], marginBottom:'1rem'}}>
            {MaxLengthString(item.projectAgency, 40)}
          </div>}
          {item.portfolioCategories &&
          <div sx={{ fontSize: '2',}}>{item.portfolioCategories.map((category: any, index: any) => {
            category = MaxLengthString(category, 20);
            if(index === item.portfolioCategories.length - 1) {
              return `${category}`
            }
            return `${category}, `
          })}</div>}
        </div>
      </div>
    </Link>
  );
};

export default RenderWorkItem;

const ImageWrapper: SxStyleProp = {
  height: '100%',
  width: '100%',
  maxHeight: 'calc(100% - 32px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '16px',
  overflow: 'hidden',
  '& > .gatsby-image-wrapper': {
    width: '100%',
    height: '100%',
  },
  '&:hover': {
    '& .work-item-details': {
      opacity: 0.8,
    },
  },
};

const ProjectDetails: SxStyleProp = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'space-between',
  opacity: [0.8, 0.8, 0],
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'flex-end',
  width: '100%',
  zIndex: 900,
  overflow: 'hidden',
  padding: ['10px','10px','20px','20px'],
  color: 'background',
  backgroundColor: 'primary',
  height: '100%',
  marginTop: '16px',
  marginBottom: '-16px',
};

const WorkItemWrapper: SxStyleProp = {
  position: 'relative',
  display: 'inline-flex',
  maxWidth: '445px',
  width: '100%',
  minHeight: '100%',
  overflow: 'hidden',
  justifyContent: 'center',
  alignItems: 'center',
  border: (theme: { colors: { primary: any; }; }) => `2px solid ${theme.colors.primary}`,
  transition: 'all 0.3s ease',
  margin: '20px 20px 0 0',
  '&:hover': {
    border: (theme: { colors: { highlight: any; }; }) => `2px solid ${theme.colors.highlight}`,
    transition: 'all 0.3s ease',
  },
};
